import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/analytics"
import "firebase/compat/storage"

import { getStripePayments } from "@stripe/firestore-stripe-payments"

const app = firebase.initializeApp({
  apiKey: "AIzaSyAjVQcfnfE5mw9k7EJdJyc6VOfd_i5uyuU",
  authDomain: "aiquizr.firebaseapp.com",
  projectId: "aiquizr",
  storageBucket: "aiquizr.appspot.com",
  messagingSenderId: "478616326507",
  appId: "1:478616326507:web:3a6658a631517c78de7c2e",
  measurementId: "G-YB1JCQRPSC",
})

firebase.analytics()

export const auth = app.auth()
export const firestore = app.firestore()
export const firebaseInstance = firebase
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider
export const storage = firebase.storage()

export default app

export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
})

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
