import React, { useState, useEffect, useRef } from "react"
import { useParams, Link } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { firestore } from "../../firebase"
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore"
import {
  FaRobot,
  FaUser,
  FaCheckCircle,
  FaTimesCircle,
  FaArrowRight,
} from "react-icons/fa"

export default function Match() {
  const { user } = useAuth()
  const { matchId } = useParams()
  const [matchData, setMatchData] = useState(null)
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState("")
  const [loading, setLoading] = useState(true)
  const [evaluation, setEvaluation] = useState(null)
  const [matchEnded, setMatchEnded] = useState(false)
  const [finalResult, setFinalResult] = useState(null)
  const messagesEndRef = useRef(null)
  const [opponentGuessed, setOpponentGuessed] = useState(false)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    const fetchMatchData = async () => {
      if (matchId && user) {
        // Add user check here
        try {
          const matchDoc = await getDoc(doc(firestore, "matches", matchId))
          if (matchDoc.exists()) {
            setMatchData({ id: matchDoc.id, ...matchDoc.data() })

            const messagesQuery = query(
              collection(firestore, `matches/${matchId}/messages`),
              orderBy("timestamp")
            )
            const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
              const newMessages = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              setMessages(newMessages)
            })

            // Listen for match updates
            const matchUnsubscribe = onSnapshot(
              doc(firestore, "matches", matchId),
              (doc) => {
                const updatedMatchData = { id: doc.id, ...doc.data() }
                setMatchData(updatedMatchData)

                // Check if opponent has guessed
                const playerKey =
                  user.id === updatedMatchData.player1.id
                    ? "player1"
                    : "player2"
                const opponentKey =
                  playerKey === "player1" ? "player2" : "player1"
                if (
                  updatedMatchData[opponentKey].guess &&
                  !updatedMatchData[playerKey].guess
                ) {
                  setOpponentGuessed(true)
                }

                if (updatedMatchData.status === "completed") {
                  setMatchEnded(true)
                  calculateFinalResult(updatedMatchData)
                }
              }
            )

            setLoading(false)
            return () => {
              unsubscribe()
              matchUnsubscribe()
            }
          } else {
            setLoading(false)
          }
        } catch (error) {
          console.error("Error fetching match data:", error)
          setLoading(false)
        }
      }
    }

    if (user) {
      // Only fetch data when user is available
      fetchMatchData()
    }
  }, [matchId, user])

  const sendMessage = async (e) => {
    e.preventDefault()
    if (newMessage.trim() === "" || !matchData) return

    const messageData = {
      text: newMessage,
      sender: user.id,
      timestamp: new Date(),
    }

    try {
      await addDoc(
        collection(firestore, `matches/${matchData.id}/messages`),
        messageData
      )
      setNewMessage("")
    } catch (error) {
      // Error handling can be improved here if needed
    }
  }

  const handleEvaluation = async (isAI) => {
    if (!matchData) return

    const playerKey = user?.id === matchData.player1.id ? "player1" : "player2"
    const opponentKey = playerKey === "player1" ? "player2" : "player1"
    const evaluationType = isAI ? "AI" : "Human"

    try {
      const matchRef = doc(firestore, "matches", matchData.id)

      const updateData = {
        [`${playerKey}.guess`]: evaluationType,
        status: "evaluating",
      }

      if (matchData[opponentKey].guess) {
        updateData.status = "evaluated"
      }

      await updateDoc(matchRef, updateData)
      setEvaluation(evaluationType)
    } catch (error) {
      // Error handling can be improved here if needed
    }
  }

  const calculateFinalResult = (matchData) => {
    const playerKey = user?.id === matchData.player1.id ? "player1" : "player2"
    const opponentKey = playerKey === "player1" ? "player2" : "player1"

    const playerGuess = matchData[playerKey].guess
    const opponentType = matchData[opponentKey].type

    const guessCorrect = playerGuess === opponentType
    const guessedCorrectly =
      matchData[opponentKey].guess === matchData[playerKey].type

    const guessPoints = guessCorrect ? 1 : -1
    const guessedPoints = guessedCorrectly ? -1 : 1

    const totalPoints = guessPoints + guessedPoints

    const finalResult = {
      opponentType,
      playerGuess,
      guessCorrect,
      guessedCorrectly,
      guessPoints,
      guessedPoints,
      totalPoints,
      playerFinalPoints:
        (matchData[playerKey].initialPoints || 0) + totalPoints,
    }
    setFinalResult(finalResult)
  }

  if (loading || !user) {
    return <div className="text-white">Loading...</div>
  }

  if (!matchData) {
    return <div className="text-white">Match not found</div>
  }

  return (
    <div className="w-full flex flex-col items-center bg-bg-color text-white flex-grow h-screen-16">
      <div className="max-w-2xl w-full h-full mx-auto px-4 py-8 flex flex-col">
        <div className="bg-neutral-800 border border-neutral-700 rounded-lg shadow-lg overflow-hidden flex-grow flex flex-col">
          <div className="p-4 border-b border-neutral-700">
            <h2 className="text-xl font-bold">Match Chat</h2>
          </div>
          <div className="flex-grow overflow-y-auto p-4">
            {messages.length === 0 ? (
              <div className="text-center text-neutral-500 text-sm">
                No messages yet. Start the conversation!
              </div>
            ) : (
              messages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-4 ${
                    message.sender === user?.id ? "text-right" : "text-left"
                  }`}
                >
                  <div
                    className={`inline-block p-2 rounded-lg text-sm max-w-[66%] ${
                      message.sender === user.id
                        ? "bg-blue-600 text-white"
                        : "bg-neutral-700 text-white"
                    }`}
                  >
                    {message.text}
                  </div>
                </div>
              ))
            )}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={sendMessage} className="p-4 bg-neutral-900">
            <div className="flex">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                className="flex-grow mr-2 p-2 rounded bg-neutral-800 text-white border border-neutral-700 focus:outline-none focus:border-blue-500"
                placeholder="Type a message..."
                disabled={opponentGuessed || matchEnded}
              />
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                disabled={opponentGuessed || matchEnded}
              >
                Send
              </button>
            </div>
          </form>

          {matchEnded && finalResult && (
            <div className="p-6 bg-neutral-800 border-t border-neutral-700 rounded-b-lg text-center">
              <div className="flex space-x-4 mb-4">
                <div
                  className={`flex-grow p-4 rounded-lg ${
                    finalResult.guessCorrect ? "bg-green-600" : "bg-red-600"
                  }`}
                >
                  <p className="text-xl font-bold mb-2">
                    Your opponent was:{" "}
                    <span className="text-yellow-300">
                      {finalResult.opponentType}
                    </span>
                  </p>
                  <p className="text-lg">
                    {finalResult.guessCorrect
                      ? "Correct guess"
                      : "Incorrect guess"}
                  </p>
                  <p className="text-xl font-bold mt-2">
                    {finalResult.guessCorrect ? "+1" : "-1"} point
                  </p>
                </div>

                <div
                  className={`w-1/3 p-4 rounded-lg ${
                    finalResult.guessedCorrectly ? "bg-red-600" : "bg-green-600"
                  }`}
                >
                  <p className="text-lg font-bold mb-2">
                    Your opponent guessed:{" "}
                    <span className="text-yellow-300">
                      {finalResult.guessedCorrectly ? "Human" : "AI"}
                    </span>
                  </p>
                  <p className="text-sm">
                    {finalResult.guessedCorrectly
                      ? "They guessed correctly"
                      : "They guessed incorrectly"}
                  </p>
                </div>
              </div>
              <Link
                to="/"
                className="block w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-6 rounded-lg transition duration-300 shadow-md flex items-center justify-center"
              >
                Try Again <FaArrowRight className="ml-2" />
              </Link>
            </div>
          )}

          {opponentGuessed && !evaluation && (
            <div className="p-4 text-center text-yellow-300 bg-neutral-900 border-t border-neutral-700">
              Your opponent has made a guess. It's time for you to guess too!
            </div>
          )}

          {!matchEnded && matchData.status !== "evaluated" && !evaluation && (
            <div className="p-4 flex justify-center space-x-4 bg-neutral-900 border-t border-neutral-700">
              <button
                onClick={() => handleEvaluation(true)}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-full transition duration-300 shadow-lg flex items-center"
              >
                <FaRobot className="mr-2" /> This is AI
              </button>
              <button
                onClick={() => handleEvaluation(false)}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-full transition duration-300 shadow-lg flex items-center"
              >
                <FaUser className="mr-2" /> This is Human
              </button>
            </div>
          )}

          {!matchEnded && evaluation && matchData.status !== "evaluated" && (
            <div className="p-4 text-center text-neutral-300 bg-neutral-900 border-t border-neutral-700">
              Waiting for opponent's evaluation...
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
