import React from "react"

export default function AlertBoarder({ color, title, text }) {
  const textColor = `${color}-800`
  const borderColor = `${color}-300`
  const bgColor = `${color}-50`

  return (
    <div
      className={`p-3 mt-4 text-${textColor} border border-${borderColor} rounded-lg bg-${bgColor} `}
    >
      <h2 className="text-lg font-medium">{title}</h2>
      <p className="text-sm font-normal">{text}</p>
    </div>
  )
}
