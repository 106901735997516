export async function handleGoogleLogin(signupWithGoogle, history, onClose) {
  try {
    const result = await signupWithGoogle()
    if (result.state === "new user" || result.state === "existing user") {
      history.push("/dashboard")
    } else {
      console.error("Login error:", result.error)
    }
  } catch (error) {
    console.error("Login error:", error)
  } finally {
    if (onClose) {
      onClose()
    }
  }
}
