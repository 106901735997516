import React, { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { firestore } from "../../firebase"
import { doc, updateDoc } from "firebase/firestore"

export default function ChangeUsernameModal({ onClose }) {
  const [newUsername, setNewUsername] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")
    setLoading(true)

    if (!newUsername.trim()) {
      setError("Username cannot be empty")
      setLoading(false)
      return
    }

    try {
      // Update display name in Firebase Auth
      await currentUser.updateProfile({
        displayName: newUsername,
      })

      // Update the user document in Firestore
      const userRef = doc(firestore, "users", currentUser.uid)
      await updateDoc(userRef, {
        displayName: newUsername,
      })

      console.log("Username updated successfully")
      onClose()
    } catch (error) {
      console.error("Error updating username:", error)
      setError("Failed to update username. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-neutral-800 p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4 text-neutral-300">
          Change Username
        </h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            placeholder="New username"
            className="w-full p-2 mb-4 bg-neutral-700 text-neutral-300 rounded"
          />
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-neutral-700 text-neutral-300 rounded hover:bg-neutral-600"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
