import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { firestore } from "../../firebase"
import {
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  collection,
  addDoc,
  getDoc,
  onSnapshot,
} from "firebase/firestore"
import Popup from "../Sign/Popup"
import Leaderboard from "./Leaderboard"
import { FaSpinner } from "react-icons/fa"
import { telegram } from "../../utils/telegram"

// Add these constants at the top of the file
const SEARCH_TIMEOUT = 10000 // 10 seconds in milliseconds
const HUMAN_OPPONENT_PROBABILITY = 0.5 // 50% chance of human opponent

export default function Dashboard() {
  const { user } = useAuth()
  const history = useHistory()
  const [status, setStatus] = useState("idle")
  const [countdown, setCountdown] = useState(0)
  const [showPopup, setShowPopup] = useState(false)
  const location = useLocation()

  const startGame = async () => {
    if (!user || !user.id) {
      setShowPopup(true)
      return
    }

    setStatus("searching")
    const userDocRef = doc(firestore, "users", user.id)

    try {
      await updateDoc(userDocRef, { status: "searching" })
      searchForOpponent()
    } catch (error) {
      setStatus("idle")
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get("start") === "true" && user && user.id) {
      startGame()
    }
  }, [location, user])

  const searchForOpponent = async () => {
    const userDocRef = doc(firestore, "users", user.id)

    const unsubscribe = onSnapshot(userDocRef, (doc) => {
      const userData = doc.data()
      if (userData.status === "found" && userData.match_id) {
        unsubscribe()
        setStatus("found")
        startCountdown(userData.match_id)
      }
    })

    let timeoutId = setTimeout(async () => {
      try {
        const userDoc = await getDoc(userDocRef)
        const userData = userDoc.data()

        if (userData.status === "searching") {
          const q = query(
            collection(firestore, "users"),
            where("status", "==", "searching"),
            where("__name__", "!=", user.id)
          )

          const querySnapshot = await getDocs(q)

          // Zufällige Auswahl zwischen Mensch und KI
          if (
            !querySnapshot.empty &&
            Math.random() < HUMAN_OPPONENT_PROBABILITY
          ) {
            const opponent = querySnapshot.docs[0]
            await createMatch(opponent.id)
          } else {
            await createMatch("AI")
          }
        }
      } catch (error) {
        console.error("Error during opponent search:", error)
        setStatus("idle")
        telegram("Error during opponent search: " + error.message)
      }
    }, SEARCH_TIMEOUT)

    return () => {
      clearTimeout(timeoutId)
      unsubscribe()
    }
  }

  const createMatch = async (opponentId) => {
    try {
      const userDocRef = doc(firestore, "users", user.id)
      const userDoc = await getDoc(userDocRef)
      const userData = userDoc.data()

      if (userData.status !== "searching") {
        return // User is already in a match or not searching
      }

      const matchRef = await addDoc(collection(firestore, "matches"), {
        player1: {
          id: user.id,
          type: "Human",
          guess: null,
        },
        player2: {
          id: opponentId,
          type: opponentId === "AI" ? "AI" : "Human",
          guess: null,
        },
        startTime: new Date(),
        status: "active",
      })

      await updateDoc(userDocRef, {
        status: "found",
        match_id: matchRef.id,
      })

      if (opponentId !== "AI") {
        const opponentDocRef = doc(firestore, "users", opponentId)
        const opponentDoc = await getDoc(opponentDocRef)
        const opponentData = opponentDoc.data()

        if (opponentData.status === "searching") {
          await updateDoc(opponentDocRef, {
            status: "found",
            match_id: matchRef.id,
          })
        } else {
          // Opponent is no longer searching, cancel the match
          await updateDoc(matchRef, { status: "cancelled" })
          await updateDoc(userDocRef, { status: "searching", match_id: null })
          searchForOpponent() // Try to find a new opponent
        }
      }
    } catch (error) {
      console.error("Error creating match:", error)
      setStatus("idle")
      telegram("Error creating match: " + error.message)
    }
  }

  const startCountdown = (matchId) => {
    let count = 5
    setCountdown(count)

    const interval = setInterval(() => {
      count--
      setCountdown(count)

      if (count === 0) {
        clearInterval(interval)
        history.push(`/match/${matchId}`)
      }
    }, 1000)
  }

  useEffect(() => {
    let timer
    if (status === "searching") {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer)
            return 0
          }
          return prevCountdown - 1
        })
      }, 1000)
    }

    return () => {
      if (timer) clearInterval(timer)
    }
  }, [status])

  return (
    <div className="bg-bg-color text-white min-h-screen flex items-center justify-center">
      <div className="max-w-4xl w-full px-4 py-16 text-center flex flex-col space-y-24">
        <div>
          <h1 className="text-5xl sm:text-6xl font-bold mb-6 leading-tight">
            <span className="text-white">Chatting with</span>{" "}
            <span className="text-blue-500">AI or Human?</span>
          </h1>

          {status === "idle" && (
            <div>
              <p className="text-xl mb-12 text-neutral-300 max-w-2xl mx-auto">
                Can you tell if it's AI or Human? Click below to start a new
                game.
              </p>
              <button
                onClick={startGame}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-8 rounded-lg text-lg transition duration-300 inline-block"
              >
                Start Game
              </button>
            </div>
          )}

          {status === "searching" && (
            <div className="mt-8">
              <p className="text-xl mb-4 text-neutral-300">
                Matching randomly with a human or an AI.
              </p>
              <div className="flex justify-center mb-4">
                <FaSpinner className="animate-spin text-4xl text-blue-500" />
              </div>
            </div>
          )}

          {status === "found" && (
            <div>
              <p className="text-2xl mb-4 text-neutral-300">
                Game will start in {countdown} seconds
              </p>
            </div>
          )}

          <div className="pt-24">
            <Leaderboard />
          </div>
        </div>
      </div>
      {!user && <Popup show={showPopup} onClose={() => setShowPopup(false)} />}
    </div>
  )
}
