import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useHistory } from "react-router-dom"
import { handleGoogleLogin } from "../../utils/authHelpers"
import ChangeUsernameModal from "./ChangeUsernameModal" // Add this import

export default function Header() {
  const { currentUser, logout, signupWithGoogle } = useAuth()
  const menuRef = useRef()
  const history = useHistory()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isChangeUsernameModalOpen, setIsChangeUsernameModalOpen] =
    useState(false)

  useEffect(() => {
    if (!localStorage.getItem("referrer")) {
      const params = new URLSearchParams(window.location.search)
      const via = params.get("via")
      const ref = params.get("ref")
      const httpReferrer = document.referrer
      const keyword = params.get("q") || params.get("text") // Google, Bing, or Yandex search term
      if (via) {
        localStorage.setItem("referrer", via)
      } else if (ref) {
        localStorage.setItem("referrer", ref)
      } else if (httpReferrer) {
        const referrerUrl = new URL(httpReferrer)
        if (
          referrerUrl.hostname.includes("google") ||
          referrerUrl.hostname.includes("bing") ||
          referrerUrl.hostname.includes("yandex")
        ) {
          localStorage.setItem("referrer", referrerUrl.hostname.split(".")[1])
          if (keyword) {
            localStorage.setItem("search_keyword", keyword)
          }
        } else {
          localStorage.setItem("referrer", httpReferrer)
        }
      }
    }
  }, [])

  const handleLogout = async () => {
    await logout()
    history.push("/")
  }

  const handleLogin = () => {
    handleGoogleLogin(signupWithGoogle, history)
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [menuRef])

  const openChangeUsernameModal = () => {
    setIsChangeUsernameModalOpen(true)
    setIsDropdownOpen(false)
  }

  const closeChangeUsernameModal = () => {
    setIsChangeUsernameModalOpen(false)
  }

  return (
    <div className="w-full bg-bg-color sticky top-0 left-0">
      <div className="max-w-6xl mx-auto px-3 md:px-8 py-4 h-16 flex justify-between text-white">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <span className="text-base md:text-lg font-bold text-neutral-300">
              🤔 AI or Human
            </span>
          </Link>
        </div>
        <div>
          <div className="flex">
            <div className="flex items-center md:gap-10 gap-2">
              {currentUser && (
                <div className="relative" ref={menuRef}>
                  <div
                    className="flex items-center space-x-2 px-2 hover:bg-neutral-700 rounded-lg p-1 cursor-pointer"
                    onClick={toggleDropdown}
                  >
                    <div className="flex items-center justify-center w-6 h-6 bg-neutral-700 rounded-full">
                      <span className="text-base text-neutral-300">
                        {currentUser.displayName
                          ? currentUser.displayName.charAt(0).toUpperCase()
                          : currentUser.email.charAt(0).toUpperCase()}
                      </span>
                    </div>
                    <span className="text-sm md:text-base font-bold text-neutral-300">
                      {currentUser.displayName ||
                        currentUser.email.split("@")[0]}
                    </span>
                  </div>
                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-neutral-800 rounded-lg shadow-lg py-1 z-10 border border-neutral-700">
                      <span className="block px-4 py-2 text-sm text-neutral-300 border-b border-neutral-700">
                        {currentUser.email}
                      </span>
                      <button
                        onClick={openChangeUsernameModal}
                        className="block w-full text-left px-4 py-2 text-sm text-neutral-300 hover:bg-neutral-700"
                      >
                        Change Username
                      </button>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-sm text-neutral-300 hover:bg-neutral-700"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              )}
              {!currentUser && (
                <button
                  className="font-bold whitespace-nowrap text-neutral-300"
                  onClick={handleLogin}
                >
                  Log in
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isChangeUsernameModalOpen && (
        <ChangeUsernameModal onClose={closeChangeUsernameModal} />
      )}
    </div>
  )
}
