import React, { useState, useEffect } from "react"
import { firestore } from "../../firebase"
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore"
import { FaRobot } from "react-icons/fa"
import { auth } from "../../firebase"

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([])
  const [currentUserEmail, setCurrentUserEmail] = useState("")
  const [currentUserData, setCurrentUserData] = useState(null)

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const leaderboardQuery = query(
          collection(firestore, "users"),
          orderBy("points", "desc"),
          limit(10)
        )
        const querySnapshot = await getDocs(leaderboardQuery)
        const leaderboardData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        setLeaderboard(leaderboardData)

        const currentUser = auth.currentUser
        if (currentUser) {
          setCurrentUserEmail(currentUser.email)
          const userDoc = await getDoc(doc(firestore, "users", currentUser.uid))
          if (userDoc.exists()) {
            setCurrentUserData(userDoc.data())
          }
        }
      } catch (error) {
        console.error("Error fetching leaderboard:", error)
      }
    }

    fetchLeaderboard()
  }, [])

  const formatUserIdentifier = (user) => {
    if (user.displayName) {
      return user.displayName
    }
    return "Anonymous"
  }

  const isCurrentUserInLeaderboard = leaderboard.some(
    (player) => player.email === currentUserEmail
  )

  return (
    <div className="mt-16">
      <h2 className="text-3xl font-bold mb-6">Leaderboard</h2>
      <div className="bg-neutral-800 rounded-lg shadow-lg overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-neutral-700">
              <th className="py-2 px-4 text-left">Rank</th>
              <th className="py-2 px-4 text-left">User</th>
              <th className="py-2 px-4 text-right">Points</th>
              <th className="py-2 px-4 text-right">Games Played</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((player, index) => (
              <tr
                key={player.id}
                className={`border-b border-neutral-700 last:border-b-0 ${
                  player.email === "AI"
                    ? "bg-blue-900"
                    : player.email === currentUserEmail
                    ? "bg-green-900"
                    : ""
                }`}
              >
                <td className="py-2 px-4">{index + 1}</td>
                <td className="py-2 px-4">
                  {player.email === "AI" ? (
                    <span className="flex items-center justify-center">
                      <FaRobot className="mr-2" /> AI
                    </span>
                  ) : (
                    <>
                      {formatUserIdentifier(player)}
                      {player.email === currentUserEmail && " (me)"}
                    </>
                  )}
                </td>
                <td className="py-2 px-4 text-right">{player.points || 0}</td>
                <td className="py-2 px-4 text-right">
                  {player.games_played || 0}
                </td>
              </tr>
            ))}
            {!isCurrentUserInLeaderboard && currentUserData && (
              <tr className="border-t-2 border-neutral-600 bg-green-900">
                <td className="py-2 px-4">-</td>
                <td className="py-2 px-4">
                  {formatUserIdentifier(currentUserData)} (me)
                </td>
                <td className="py-2 px-4 text-right">
                  {currentUserData.points || 0}
                </td>
                <td className="py-2 px-4 text-right">
                  {currentUserData.games_played || 0}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Leaderboard
